
import { defineComponent, ref, reactive, computed } from 'vue'
import axios from '@/http'

function getDateInString(date: Date) {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toString()
}
function getDateInTimeZone(d: Date, timeZone: number) {
	let stringDate = d.toString()
	const gmtIdx = stringDate.indexOf('GMT')
	if (gmtIdx === -1) return d
	stringDate = `${stringDate.slice(0, gmtIdx)}GMT+0${timeZone}00`
	return new Date(stringDate)
}
interface ISelectedDate {
	begin: '' | Date
	end: '' | Date
}
export default defineComponent({
	name: 'ReportIndexPage',
	setup() {
		const selectedDate: ISelectedDate = reactive({
			begin: '',
			end: ''
		})
		const loadingState = reactive({
			order: false,
			variation: false
		})
		// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		const orderReports = ref<Array<{ [key: string]: any }>>([])
		// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		const variationsReports = ref<Array<{ [key: string]: any }>>([])
		const errorText = ref<string>('')
		const disableShowReportOrder = computed(() => Boolean(errorText.value || !selectedDate.begin || !selectedDate.end))
		const disableUploadCSVReportOrder = computed(() =>
			Boolean(disableShowReportOrder.value || !orderReports.value.length || loadingState.order)
		)
		const disableUploadCSVReportVariation = computed(() =>
			Boolean(disableUploadCSVReportOrder.value || !variationsReports.value.length)
		)
		function checkDate() {
			const { begin, end } = selectedDate
			if (begin && end && begin > end) {
				errorText.value = 'Дата начала должна быть раньше даты окончания'
				return
			}
			if (begin && new Date(getDateInString(begin)).getTime() >= new Date(getDateInString(new Date())).getTime()) {
				errorText.value = 'Отчет можно построить только за вчерашний день и ранее'
				return
			}
			if (end && new Date(getDateInString(end)).getTime() >= new Date(getDateInString(new Date())).getTime()) {
				errorText.value = 'Отчет можно построить только за вчерашний день и ранее'
				return
			}
			errorText.value = ''
		}
		async function getOrderReport() {
			// eslint-disable-next-line  @typescript-eslint/no-explicit-any
			function getType(type: any) {
				if (type === 1) return 'По счету'
				if (type === 2) return 'Карта'
			}
			try {
				if (!selectedDate.begin || !selectedDate.end) return
				loadingState.order = true
				const res = await axios.post('/order/order-reports-by-dates', {
					begin: getDateInTimeZone(selectedDate.begin, 3),
					end: getDateInTimeZone(selectedDate.end, 3)
				})
				if (!res?.data) return
				orderReports.value = []
				for (const report of res.data) {
					const order = report?.orders?.[0]

					const item = {
						...report,
						type: getType(report.type),
						// modified: JSON.stringify(getDateInTimeZone(new Date(report.modified), 3)).replaceAll('"', ''),
						modified: report.modified.toString(),
						orderId: order.id,
						// orderCreated: JSON.stringify(getDateInTimeZone(new Date(order.created), 3)).replaceAll('"', ''),
						orderCreated: order.created.toString(),
						orderManagerId: order.manager_id,
						orderVariations: order.variations,
						orderUnits: order.units
					}
					delete item.orders
					orderReports.value.push(item)
				}
				variationsReports.value = []
			} catch (e) {
				console.error(e)
				alert('что пошло не так')
			} finally {
				loadingState.order = false
			}
		}
		async function getVariationReport() {
			// eslint-disable-next-line  @typescript-eslint/no-explicit-any
			function getType(type: any) {
				return type ? 'Лот' : 'Штучный'
			}
			try {
				if (!selectedDate.begin || !selectedDate.end) return
				loadingState.variation = true
				const res = await axios.post('/order/variation-reports-by-ids', {
					ids: orderReports.value.map(o => Number(o.orderId))
				})
				if (!res?.data) return
				variationsReports.value = []
				for (const report of res.data) {
					const order = report?.order
					const orderPayment = order?.orderPayment
					const variation = report?.variation
					const product = variation?.product

					const item = {
						...report,
						// orderCreated: JSON.stringify(getDateInTimeZone(new Date(order.created), 3)).replaceAll('"', ''),
						orderCreated: order.created.toString(),
						productType: getType(product.isBundle),
						productName: product.name,
						productParam1: product.internalParam1,
						productParam2: product.internalParam2,
						currency: orderPayment.currency
					}
					delete item.orders
					variationsReports.value.push(item)
				}
			} catch (e) {
				console.error(e)
				alert('что пошло не так')
			} finally {
				loadingState.variation = false
			}
		}
		function downloadTableAsCsv(table_id: string, filename: string, separator = ',') {
			var rows = document.querySelectorAll('table#' + table_id + ' tr')
			var csv = []
			for (var i = 0; i < rows.length; i++) {
				var row = [],
					cols = rows[i].querySelectorAll('td, th')
				for (var j = 0; j < cols.length; j++) {
					var data = (cols[j] as HTMLElement).innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
					data = data.replace(/"/g, '""')
					row.push('"' + data + '"')
				}
				csv.push(row.join(separator))
			}
			var csv_string = csv.join('\n')
			var link = document.createElement('a')
			link.style.display = 'none'
			link.setAttribute('target', '_blank')
			link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string))
			link.setAttribute('download', filename)
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
		function exportOrderCsv() {
			const { begin, end } = selectedDate
			if (!begin || !end) return
			function getDateStringWithoutTime(date: Date) {
				var dd = String(date.getDate()).padStart(2, '0')
				var mm = String(date.getMonth() + 1).padStart(2, '0')
				var yyyy = date.getFullYear()
				return yyyy + '_' + mm + '_' + dd
			}
			const beginDate = getDateStringWithoutTime(begin)
			const endDate = getDateStringWithoutTime(end)
			downloadTableAsCsv('order_report_table', `orders_${beginDate}-${endDate}.csv`)
		}
		function exportVariationCsv() {
			const { begin, end } = selectedDate
			if (!begin || !end) return
			function getDateStringWithoutTime(date: Date) {
				var dd = String(date.getDate()).padStart(2, '0')
				var mm = String(date.getMonth() + 1).padStart(2, '0')
				var yyyy = date.getFullYear()
				return yyyy + '_' + mm + '_' + dd
			}
			const beginDate = getDateStringWithoutTime(begin)
			const endDate = getDateStringWithoutTime(end)
			downloadTableAsCsv('variation_report_table', `variations_${beginDate}-${endDate}.csv`)
		}
		return {
			selectedDate,
			errorText,
			disableShowReportOrder,
			loadingState,
			orderReports,
			variationsReports,
			disableUploadCSVReportOrder,
			disableUploadCSVReportVariation,

			checkDate,
			getOrderReport,
			getVariationReport,
			exportOrderCsv,
			exportVariationCsv
		}
	}
})
